import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import Lottie from 'react-lottie';
import animationData from "assets/animation/pagination-loader.json";
import CurationCardSkeleton from "../Skeletons/CurationCardSkeleton/CurationCardSkeleton";
import ActivePlayoffSkeleton from "../Skeletons/ActivePlayoffSkeleton/ActivePlayoffSkeleton";
import ProductCardSkeleton from "../Skeletons/ProductCardSkeleton/ProductCardSkeleton";

export const PaginationLoader=({shimmertype, pageNumber, mX, isListingScreen})=> {
  const options = {
    animationData: animationData,
    loop: true,
  };
  // const { View } = useLottie(options);
  return (<>
    {shimmertype=='product_card'? 
    <Box mt={2} sx={{marginX:mX}}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ProductCardSkeleton></ProductCardSkeleton>
        </Grid>
        <Grid item xs={6}>
          <ProductCardSkeleton></ProductCardSkeleton>
        </Grid>
      </Grid>
    </Box>
    :shimmertype=="curation"?
     <Stack direction="column" spacing={2} sx={{marginX:mX}}>
      {Array.from(new Array(pageNumber<=1 && isListingScreen?3:1)).map(
        (item, index) => {
          return (									
            <CurationCardSkeleton key={"loader2"+index}></CurationCardSkeleton>						
          );
        }
      )}    
    </Stack>:
      <Box>
      <ActivePlayoffSkeleton firstCard={true}></ActivePlayoffSkeleton>
      {Array.from(new Array(pageNumber<=1?2:1)).map(
        (item, index) => {
          return (									
            <Box sx={{marginY:2.5}} key={"playoffSk"+ index}>
              <ActivePlayoffSkeleton challengeSection={true} ></ActivePlayoffSkeleton> 
            </Box>					
          );
        }
      )}   
      </Box>

  }
  </>
   
  );
}


