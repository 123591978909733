import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import Image from "next/image"
import noCurationImage from "assets/images/no_curation_image.svg";

export const EmptyContainer=({title,subtitle,image, width, height})=>{
    return <Box sx={{ textAlign: "center"}}  mx={2.5} >
    <Image
    
        width={width}
        height={height}
        alt="No Curation"
        src={image?image:noCurationImage}
    ></Image>
    <Typography variant="h2" my={2}>
        {title}
    </Typography>
    <Typography variant="body2" sx={{ opacity: 0.4 }}>
        {subtitle}
    </Typography>
</Box>


}
EmptyContainer.defaultProps={
    subtitle:"Please check back later",
    width:127,
    height:151,
    alt:"No Curation",
    src:{noCurationImage}
}
